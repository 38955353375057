import "./style.css";
import arrow from "./arrow.svg";
import { useContext, useEffect, useState } from "react";
import { Theme } from "../../../Contexts/Theme";
export default function FilterCut({ items, title, opens }) {
  const [open, isOpen] = useState(false);
  const [theme, setTheme] = useContext(Theme);
  useEffect(() => {
    if (opens == "true") {
      isOpen(true);
    }
  }, []);
  return (
    <div className="filterCat">
      <div className="filterTitle" onClick={() => isOpen(!open)}>
        <p>{title}</p>

        <svg
          style={open ? { transform: "rotate(180deg)" } : {}}
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 7L6 2L11 7"
            stroke="var(--color-text-one)"
            stroke-width="2"
          />
        </svg>
      </div>
      {items &&
        open &&
        items.length > 0 &&
        items.map((em, index) => {
          return (
            <div className="checkBoxCont" key={index}>
              <input type="checkbox" name="" id="" />
              <p style={{ color: "var(--color-text-one)" }}>{em}</p>
            </div>
          );
        })}
    </div>
  );
}
