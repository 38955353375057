import FilterCut from "./FilterCat";
import "./style.css";
import arrow from "./FilterCat/arrow.svg";
import { useContext, useEffect, useState } from "react";
import { Theme } from "../../Contexts/Theme";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { id } from "../../Consts/Const";

const test2 = [
  "Весенняя распродажа",
  "Скидка -40%",
  "Скидка -50%",
  "Скидка -60%",
  "Утилизация",
];
export default function Filters() {
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  console.log(categories);
  function getCategories() {
    let formData = new FormData();
    formData.append("idOwner", id);
    fetch("https://comeback-team.ru/admin/getCategory.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCategories(
          data.map((em) => {
            return em.Description;
          })
        );
      });
  }
  function getTypes() {
    let formData = new FormData();
    formData.append("idOwner", id);
    fetch("https://comeback-team.ru/admin/getType.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setTypes(
          data.map((em) => {
            return em.Type;
          })
        );
      });
  }
  const [price, setPrice] = useState(1);
  const [theme, setTheme] = useContext(Theme);
  const [range, setRange] = useState({
    min: 0,
    max: 10000,
  });
  useEffect(() => {
    getCategories();
    getTypes();
  }, []);
  return (
    <div className="Filters">
      <FilterCut
        items={categories}
        title={"Категории"}
        opens={"true"}
      ></FilterCut>
      <div className="filterCat">
        <div
          className="filterTitle"
          style={{
            background: "--services-filter-background",
            color: "var(--color-text-one)",
          }}
        >
          <p>Цена</p>
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 7L6 2L11 7"
              stroke="var(--color-text-one)"
              stroke-width="2"
            />
          </svg>
        </div>
        <div className="rangeType">
          <InputRange
            maxValue={10000}
            minValue={0}
            value={range}
            onChange={(value) => setRange(value)}
            onChangeComplete={(value) => console.log(value)}
          />
          <div className="inputsPrice">
            <input
              type="text"
              value={"от " + range.min}
              onChange={(e) =>
                e.target.value.replace("от ", "") < range.max &&
                setRange({ ...range, min: e.target.value.replace("от ", "") })
              }
            />
            <input
              type="text"
              value={"до " + range.max}
              onChange={(e) =>
                e.target.value.replace("от ", "") > range.min &&
                setRange({ ...range, max: e.target.value.replace("до ", "") })
              }
            />
          </div>
        </div>
      </div>
      <FilterCut
        items={test2}
        title={"Спецпредложения"}
        opens={"true"}
      ></FilterCut>
      <FilterCut items={types} title={"Тип"} opens={"true"}></FilterCut>
    </div>
  );
}
