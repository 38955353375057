import { useContext, useEffect, useRef, useState } from "react";
import Card from "../../Components/Card";
import "./style.css";
import { Nav } from "../../Contexts/Nav";
import { id } from "../../Consts/Const";
import { Theme } from "../../Contexts/Theme";
import { DataMain } from "../../Contexts/Data";
import { Fade, Zoom } from "react-reveal";
import i1 from "./1.png";
import i2 from "./2.png";
import sl1 from "./Слайд1.webp";
import sl2 from "./Слайд2.webp";
import Categories from "../../Components/Categories";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
const arr1 = [11, 10, 0, 13, 5, 64, 10, 1, 135, 12];
const arr2 = [15, 9, 83, 7, 3, 11, 122, 11, 2, 6];
const arr3 = [18, 73, 18, 1, 17, 16, 8, 0, 0, 6];
const arr4 = [18, 63, 10, 4, 0, 73, 37, 8, 0, 19];
const arr5 = [18, 4, 13, 157, 19, 125, 2, 65, 20, 132];
export default function CardList({ width, search }) {
  const [list, setList] = useContext(DataMain);
  const [theme, setTheme] = useContext(Theme);
  const Navigate = useNavigate();
  const [stocks, setStocks] = useState([]);
  const [hots, setHots] = useState(list.hots);
  const scrolls = useRef(null);
  const location = useLocation();
  const [numElems, setNumElems] = useState(
    document.body.clientWidth > 640 ? 8 : 50
  );

  function getStocks() {
    let formData = new FormData();
    formData.append("ID", id);
    fetch("https://comeback-team.ru/getStocks.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setStocks(data);
      });
  }

  useEffect(() => {
    getStocks();

    scrolls.current.scrollTop = list.scrollY;
  }, []);
  return (
    <div
      className="cardList"
      /*   style={{
        width: nav ? "56.5vw" : "75vw",
      }} */
      ref={scrolls}
      onScroll={(e) => {
        setList((scroll) => ({ ...scroll, scrollY: e.target.scrollTop }));
        if (e.target.scrollTop > (400 / 8) * numElems) {
          setNumElems(numElems + 8);
        }
      }}
    >
      {location.pathname != routes.services && (
        <div className="actionsInList">
          <video
            style={{ boxShadow: theme.shadowCard }}
            src={
              list && list.userInfo && list.userInfo.Actions
                ? JSON.parse(list.userInfo.Actions)[0]
                : ""
            }
            muted={true}
            autoPlay={true}
            loop={true}
            playsInline
            controls={false}
            onClick={() =>
              Navigate(
                `/card/${
                  list && list.userInfo && list.userInfo.idActions
                    ? JSON.parse(list.userInfo.idActions)[0]
                    : ""
                }`
              )
            }
          ></video>
          {document.body.clientWidth > 640 && (
            <video
              style={{ boxShadow: theme.shadowCard }}
              src={
                list && list.userInfo && list.userInfo.Actions
                  ? JSON.parse(list.userInfo.Actions)[1]
                  : ""
              }
              muted={true}
              playsInline
              controls={false}
              autoPlay={true}
              loop={true}
              onClick={() =>
                Navigate(
                  `/card/${
                    list && list.userInfo && list.userInfo.idActions
                      ? JSON.parse(list.userInfo.idActions)[1]
                      : ""
                  }`
                )
              }
            ></video>
          )}
        </div>
      )}
      {/*     <Categories></Categories> */}
      {list.dataM &&
        list.dataM.length > 0 &&
        list.dataM.map((em, index) => {
          if (index <= numElems && index < 12) {
            return (
              <Card
                cardOptions={em}
                key={index}
                arrs={[arr1, arr2, arr3, arr4, arr5]}
              ></Card>
            );
          }
        })}
      {location.pathname != routes.services && (
        <div className="actionsInList">
          <video
            style={{ boxShadow: theme.shadowCard }}
            src={
              list && list.userInfo && list.userInfo.Actions
                ? JSON.parse(list.userInfo.Actions)[2]
                : ""
            }
            muted={true}
            autoPlay={true}
            loop={true}
            playsInline
            controls={false}
            onClick={() =>
              Navigate(
                `/card/${
                  list && list.userInfo && list.userInfo.idActions
                    ? JSON.parse(list.userInfo.idActions)[2]
                    : ""
                }`
              )
            }
          ></video>
          {document.body.clientWidth > 640 && (
            <video
              style={{ boxShadow: theme.shadowCard }}
              src={
                list && list.userInfo && list.userInfo.Actions
                  ? JSON.parse(list.userInfo.Actions)[3]
                  : ""
              }
              muted={true}
              playsInline
              controls={false}
              autoPlay={true}
              loop={true}
              onClick={() =>
                Navigate(
                  `/card/${
                    list && list.userInfo && list.userInfo.idActions
                      ? JSON.parse(list.userInfo.idActions)[3]
                      : ""
                  }`
                )
              }
            ></video>
          )}
        </div>
      )}
      {list.dataM &&
        list.dataM.length > 0 &&
        list.dataM.map((em, index) => {
          if (index <= numElems && index >= 12) {
            return (
              <Card
                cardOptions={em}
                key={index}
                arrs={[arr1, arr2, arr3, arr4, arr5]}
              ></Card>
            );
          }
        })}
      {location.pathname != routes.services && (
        <div className="actionsInList">
          {document.body.clientWidth < 640 && (
            <video
              style={{ boxShadow: theme.shadowCard }}
              src={
                list && list.userInfo && list.userInfo.Actions
                  ? JSON.parse(list.userInfo.Actions)[3]
                  : ""
              }
              muted={true}
              playsInline
              controls={false}
              autoPlay={true}
              loop={true}
              onClick={() =>
                Navigate(
                  `/card/${
                    list && list.userInfo && list.userInfo.idActions
                      ? JSON.parse(list.userInfo.idActions)[3]
                      : ""
                  }`
                )
              }
            ></video>
          )}
        </div>
      )}
    </div>
  );
}
