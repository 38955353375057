import { useContext, useEffect, useState } from "react";
import ThemeCont from "../Theme";
import "./style.css";
import { Theme } from "../../Contexts/Theme";
import loop from "../../Icons/Loop.svg";
import { Fade, Flip, Slide, Zoom } from "react-reveal";
import { DataMain } from "../../Contexts/Data";
import { useNavigate } from "react-router-dom";
import { StoryItems } from "../../Contexts/Story";
import { useRef } from "react";
import cross from "./cross.svg";
import { Nav } from "../../Contexts/Nav";
export default function ServiceSearch({ setSearch, search }) {
  const [theme, setTheme] = useContext(Theme);
  const [active, setActive] = useState(false);
  const [nav, setNav] = useContext(Nav);
  const [storySearch, setStorySearch] = useContext(StoryItems);
  const Navigate = useNavigate();
  const [items, setItems] = useContext(DataMain);
  const inputR = useRef(null);
  useEffect(() => {
    active && document.body.clientWidth < 640 && inputR.current.focus();
  }, [active]);
  function addStory(value) {
    setStorySearch((storySearch) => [...storySearch, value]);
  }
  const Fixed = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    if (document.body.clientWidth < 640) {
      window.addEventListener("scroll", handleScroll, { passive: true });
    }
    return () => {
      document.body.clientWidth < 640 &&
        window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      className={`serviceSearch ${scrollPosition > 100 && "searchFly"}`}
      ref={Fixed}
    >
      {active && document.body.clientWidth > 640 && (
        <div className="darkness" onClick={() => setActive(false)}></div>
      )}
      {active && document.body.clientWidth > 640 && (
        <Fade top>
          <div className="omg" style={nav ? { marginLeft: "160px" } : {}}>
            <h3>
              {search.length > 0
                ? "Результаты поиска:"
                : "Рекомендуемые товары:"}
            </h3>
            <div className="searchResults">
              {items &&
                items.dataM &&
                items.dataM.map((em) => {
                  if (em.Title.toLowerCase().includes(search.toLowerCase())) {
                    return (
                      <div
                        className="searchResultsItem"
                        onClick={() => {
                          addStory(em.Title);

                          Navigate(`/card/${em.ID}`);
                        }}
                      >
                        <img src={em.Photo.split(" ")[0]} alt="" />
                        <div>
                          <div className="desInSearch">
                            <p>
                              {em.Title.substr(0, 22)}
                              {em.Title.length > 22 && "..."}
                            </p>
                            <span>{em.Price} ₽</span>
                            <div>В наличии: в 3 магазинах</div>
                          </div>
                          <i>{em.Description}</i>
                          <article>
                            <b>К товару &gt;</b>
                          </article>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </Fade>
      )}
      {active && document.body.clientWidth < 640 && (
        <Fade>
          <div
            className="inputContainer"
            style={{ background: "var(--background-app)" }}
          >
            <div className="inputBody">
              <div
                className="loopInput"
                style={{ background: theme.searchColor }}
              >
                <input
                  type="text"
                  ref={inputR}
                  placeholder="Введите название..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <img
                  src={search.length > 0 ? cross : loop}
                  alt=""
                  onClick={() => search.length > 0 && setSearch("")}
                />
              </div>
              <div
                className="closeSearch"
                onClick={() => {
                  setSearch("");
                  setActive(false);
                }}
              >
                Отмена
              </div>
            </div>
            <div className="story">
              <div className="storyT">
                <h4>История</h4>
                <p
                  onClick={() => {
                    setStorySearch([]);
                  }}
                >
                  Очистить
                </p>
              </div>
              <div className="storyBody">
                {storySearch &&
                  storySearch.length > 0 &&
                  storySearch.map((em, index) => {
                    return (
                      <div className="storyItem" onClick={() => setSearch(em)}>
                        {em}
                      </div>
                    );
                  })}
              </div>
            </div>
            <h4>Рекомендуемые товары</h4>
            <div className="searchResults">
              {items &&
                items.dataM &&
                items.dataM.map((em) => {
                  if (em.Title.toLowerCase().includes(search.toLowerCase())) {
                    return (
                      <div
                        className="searchResultsItem"
                        onClick={() => {
                          addStory(em.Title);

                          Navigate(`/card/${em.ID}`);
                        }}
                      >
                        <img src={em.Photo.split(" ")[0]} alt="" />
                        <div>
                          <p>
                            {em.Title.substr(0, 22)}
                            {em.Title.length > 22 && "..."}
                          </p>
                          <span>{em.Price} ₽</span>
                          <div>В наличии: в 3 магазинах</div>
                          <article>
                            К товару <b>&gt;</b>
                          </article>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </Fade>
      )}
      <ThemeCont></ThemeCont>
      <div
        className="loopInput"
        style={{ background: "var(--background-input)" }}
      >
        <input
          type="text"
          placeholder="Поиск..."
          value={search}
          onFocus={() => setActive(true)}
          onChange={(e) =>
            document.body.clientWidth > 640 && setSearch(e.target.value)
          }
        />
        <img src={loop} alt="" />
      </div>
    </div>
  );
}
