import logo from "./img/logo.svg";
import icon from "./img/icon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import { useContext } from "react";
import { Theme } from "../../Contexts/Theme";
import outIcon from "./img/out.svg";
import icon1 from "./img/icon1.svg";

import icon2 from "./img/icon2.svg";

import icon3 from "./img/icon3.svg";

import icon4 from "./img/icon4.svg";

import icon5 from "./img/icon5.svg";

import icon6 from "./img/icon6.svg";
import iconProfile from "./img/iconProfile.svg";
import icon7 from "./img/icon7.svg";
import close from "./img/close.svg";
import { routes } from "../../routes";
import { BusketV } from "../../Contexts/Busket";
import { useEffect } from "react";
import { DataMain } from "../../Contexts/Data";
import { User } from "../../Contexts/User";
import { id } from "../../Consts/Const";
export default function NavBar({ nav, isNav }) {
  const location = useLocation();
  const [opt, setOpts] = useContext(Theme);
  const [auth, setAuth] = useContext(User);
  const [scrolling, setScrolling] = useContext(DataMain);
  const Navigate = useNavigate();
  const [busket, setBusket] = useContext(BusketV);
  const [data, setData] = useContext(DataMain);
  useEffect(() => {
    document.body.clientWidth < 640 && isNav(false);
  }, [location.pathname]);
  console.log(auth);
  return (
    <div
      className="nav"
      style={{
        //background: opt.background,
        width: nav ? "220px" : document.body.clientWidth > 640 ? "60px" : "0px",
        minWidth: nav
          ? "220px"
          : document.body.clientWidth > 640
          ? "60px"
          : "0px",
        paddingLeft: nav ? "20px" : "10px",
        padding: !nav && document.body.clientWidth < 640 && "0",
      }}
    >
      <img src={data && data.userInfo ? data.userInfo.Logo : ""} alt="" />
      {document.body.clientWidth < 640 && (
        <img src={close} onClick={() => isNav(!nav)} className="crest" />
      )}
      <div>
        {auth && auth.Phone && auth.Phone.length > 0 && (
          <div className="account" onClick={() => Navigate(routes.profile)}>
            <img
              src={
                auth && auth.Photo && auth.Photo.length > 0
                  ? auth.Photo
                  : iconProfile
              }
              alt=""
              style={
                !nav
                  ? { width: "40px", height: "40px", marginLeft: "5px" }
                  : { width: "50px", height: "50px" }
              }
            />
            <div>
              <p>{auth && auth.FIO.split(" ")[0]}</p>
              <span>Новичок</span>
            </div>
          </div>
        )}
        {auth.length == 0 && (
          <Link to={routes.login}>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.5 7.00024C13.6075 7.00024 8 12.6078 8 19.5002C8 26.3922 13.6095 32.0002 20.5 32.0002C27.4042 32.0002 33 26.3781 33 19.5002C33 12.6078 27.3925 7.00024 20.5 7.00024ZM20.5 30.3698C17.9545 30.3698 15.6109 29.4899 13.7564 28.0188C14.9046 25.3404 17.5484 23.5764 20.5 23.5764C23.4521 23.5764 26.0963 25.34 27.2447 28.0179C25.3901 29.4896 23.046 30.3698 20.5 30.3698ZM16.4239 17.8698C16.4239 15.6223 18.2524 13.7937 20.5 13.7937C22.7476 13.7937 24.5761 15.6223 24.5761 17.8698C24.5761 20.1174 22.7476 21.9459 20.5 21.9459C18.2524 21.9459 16.4239 20.1174 16.4239 17.8698ZM28.499 26.8516C27.4914 24.8659 25.7879 23.3472 23.7531 22.5555C25.2348 21.5237 26.2065 19.8083 26.2065 17.8698C26.2065 14.7232 23.6466 12.1633 20.5 12.1633C17.3534 12.1633 14.7935 14.7232 14.7935 17.8698C14.7935 19.8083 15.7653 21.5239 17.2472 22.5557C15.2126 23.3476 13.5095 24.8667 12.5021 26.8527C10.7202 24.916 9.63042 22.3331 9.63042 19.5002C9.63042 13.5068 14.5065 8.63066 20.5 8.63066C26.4935 8.63066 31.3696 13.5068 31.3696 19.5002C31.3696 22.3326 30.2802 24.915 28.499 26.8516Z"
                fill="var(--color-text-opacity)"
              />
            </svg>

            <p
              className="authB"
              style={
                location.pathname == routes.login
                  ? { fontWeight: "600", color: "var(--color-text-two)" }
                  : {}
              }
            >
              Ваш профиль
            </p>
          </Link>
        )}
        <Link
          to={routes.main}
          onClick={() => setScrolling((scroll) => ({ ...scroll, scrollY: 0 }))}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 24C17.85 24.63 18.885 25 20 25C21.115 25 22.15 24.63 23 24"
              stroke={
                location.pathname == routes.main
                  ? "var(--color-text-two)"
                  : "var(--base-icon-color)"
              }
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M30 20.204V21.725C30 25.625 30 27.576 28.828 28.788C27.657 30 25.771 30 22 30H18C14.229 30 12.343 30 11.172 28.788C10 27.576 10 25.626 10 21.725V20.204C10 17.915 10 16.771 10.52 15.823C11.038 14.874 11.987 14.286 13.884 13.108L15.884 11.867C17.889 10.622 18.892 10 20 10C21.108 10 22.11 10.622 24.116 11.867L26.116 13.108C28.013 14.286 28.962 14.874 29.481 15.823"
              stroke={
                location.pathname == routes.main
                  ? "var(--color-text-two)"
                  : "var(--color-text-opacity)"
              }
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>

          <p
            style={
              location.pathname == routes.main
                ? { fontWeight: "600", color: "var(--color-text-two)" }
                : {}
            }
          >
            Главная
          </p>
        </Link>
        <Link
          to={routes.services}
          onClick={() => setScrolling((scroll) => ({ ...scroll, scrollY: 0 }))}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5078 19V22C11.5078 25.771 11.5078 27.657 12.6798 28.828C13.8508 30 15.7368 30 19.5078 30H20.5078C24.2788 30 26.1648 30 27.3358 28.828C28.5078 27.657 28.5078 25.771 28.5078 22V19"
              stroke={
                location.pathname == routes.services
                  ? "var(--color-text-two)"
                  : "var(--base-icon-color)"
              }
              stroke-width="1.5"
            />
            <path
              d="M17.5029 10H22.5029L23.1549 16.517C23.1979 16.9568 23.1483 17.4007 23.0095 17.8201C22.8706 18.2396 22.6455 18.6254 22.3487 18.9526C22.0518 19.2799 21.6897 19.5415 21.2858 19.7204C20.8818 19.8994 20.4448 19.9919 20.0029 19.9919C19.5611 19.9919 19.1241 19.8994 18.7201 19.7204C18.3161 19.5415 17.9541 19.2799 17.6572 18.9526C17.3603 18.6254 17.1352 18.2396 16.9964 17.8201C16.8575 17.4007 16.808 16.9568 16.8509 16.517L17.5029 10Z"
              stroke={
                location.pathname == routes.services
                  ? "var(--color-text-two)"
                  : "var(--base-icon-color)"
              }
              stroke-width="1.5"
            />
            <path
              d="M11.3339 13.351C11.5119 12.461 11.6009 12.016 11.7819 11.655C11.9705 11.279 12.2361 10.9469 12.5613 10.6803C12.8866 10.4136 13.2643 10.2183 13.6699 10.107C14.0599 10 14.5139 10 15.4219 10H17.5039L16.7789 17.245C16.7407 17.6596 16.6182 18.0621 16.419 18.4277C16.2198 18.7934 15.948 19.1145 15.6204 19.3715C15.2928 19.6285 14.9162 19.8159 14.5136 19.9223C14.111 20.0287 13.691 20.0518 13.2792 19.9901C12.8673 19.9285 12.4724 19.7835 12.1186 19.564C11.7648 19.3445 11.4595 19.0551 11.2214 18.7135C10.9833 18.3718 10.8175 17.9852 10.734 17.5773C10.6505 17.1694 10.6512 16.7487 10.7359 16.341L11.3339 13.351ZM28.6739 13.351C28.4959 12.461 28.4069 12.016 28.2259 11.655C28.0373 11.279 27.7718 10.9469 27.4465 10.6803C27.1213 10.4136 26.7436 10.2183 26.3379 10.107C25.9479 10 25.4939 10 24.5859 10H22.5039L23.2289 17.245C23.2672 17.6596 23.3897 18.0621 23.5889 18.4277C23.7881 18.7934 24.0598 19.1145 24.3874 19.3715C24.7151 19.6285 25.0917 19.8159 25.4943 19.9223C25.8968 20.0287 26.3169 20.0518 26.7287 19.9901C27.1405 19.9285 27.5354 19.7835 27.8892 19.564C28.2431 19.3445 28.5484 19.0551 28.7864 18.7135C29.0245 18.3718 29.1904 17.9852 29.2739 17.5773C29.3573 17.1694 29.3567 16.7487 29.2719 16.341L28.6739 13.351Z"
              stroke={
                location.pathname == routes.services
                  ? "var(--color-text-two)"
                  : "var(--base-icon-color)"
              }
              stroke-width="1.5"
            />
            <path
              d="M17.5078 29.5V26.5C17.5078 25.565 17.5078 25.098 17.7088 24.75C17.8405 24.522 18.0298 24.3326 18.2578 24.201C18.6058 24 19.0728 24 20.0078 24C20.9428 24 21.4098 24 21.7578 24.201C21.9858 24.3326 22.1752 24.522 22.3068 24.75C22.5078 25.098 22.5078 25.565 22.5078 26.5V29.5"
              stroke={
                location.pathname == routes.services
                  ? "var(--color-text-two)"
                  : "var(--base-icon-color)"
              }
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>

          <p
            style={
              location.pathname == routes.services
                ? { fontWeight: "600", color: "var(--color-text-two)" }
                : {}
            }
          >
            Каталог
          </p>
        </Link>
        <Link to={routes.basket}>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23 18C23.5523 18 24 17.5523 24 17C24 16.4477 23.5523 16 23 16C22.4477 16 22 16.4477 22 17C22 17.5523 22.4477 18 23 18Z"
              fill="#9B796A"
            />
            <path
              d="M17 18C17.5523 18 18 17.5523 18 17C18 16.4477 17.5523 16 17 16C16.4477 16 16 16.4477 16 17C16 17.5523 16.4477 18 17 18Z"
              fill="#9B796A"
            />
            <path
              d="M17.0008 14V13C17.0008 12.2044 17.3169 11.4413 17.8795 10.8787C18.4421 10.3161 19.2051 10 20.0008 10C20.7964 10 21.5595 10.3161 22.1221 10.8787C22.6847 11.4413 23.0008 12.2044 23.0008 13V14M28.2248 20.526C27.6388 17.405 27.3468 15.844 26.2348 14.922C25.1258 14 23.5378 14 20.3608 14H19.6408C16.4648 14 14.8768 14 13.7658 14.922C12.6558 15.844 12.3628 17.405 11.7768 20.526C10.9538 24.915 10.5428 27.109 11.7428 28.555C12.9428 30 15.1748 30 19.6398 30H20.3618C24.8268 30 27.0598 30 28.2588 28.555C28.9548 27.715 29.1088 26.625 28.9548 25"
              stroke={
                location.pathname == routes.basket
                  ? "var(--color-text-two)"
                  : "var(--base-icon-color)"
              }
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>

          <p
            style={
              location.pathname == routes.basket
                ? { fontWeight: "600", color: "var(--color-text-two)" }
                : {}
            }
          >
            Корзина
          </p>

          {busket && busket.length > 0 && (
            <span
              className="numLength"
              style={!nav ? { transform: "translate(-110px,-5px)" } : {}}
            >
              {busket.length}
            </span>
          )}
        </Link>
        <Link to={routes.about}>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30 30H10M29 30V21M19.004 13C19.022 11.76 19.114 11.057 19.586 10.586C20.172 10 21.114 10 23 10H25C26.886 10 27.828 10 28.414 10.586C29 11.172 29 12.114 29 14V17"
              stroke={
                location.pathname == routes.about
                  ? "var(--color-text-two)"
                  : "var(--base-icon-color)"
              }
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M23 30V24M11 30V21M11 17C11 15.114 11 14.172 11.586 13.586C12.172 13 13.114 13 15 13H19C20.886 13 21.828 13 22.414 13.586C23 14.172 23 15.114 23 17V20M17 30V27M14 16H20M14 19H15M20 19H17.5M14 22H20"
              stroke={
                location.pathname == routes.about
                  ? "var(--color-text-two)"
                  : "var(--base-icon-color)"
              }
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>

          <p
            style={
              location.pathname == routes.about
                ? { fontWeight: "600", color: "var(--color-text-two)" }
                : {}
            }
          >
            О компании
          </p>
        </Link>
        <Link to={routes.contacts}>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.718 11.0933C13.944 9.80225 15.972 10.0433 16.986 11.4773L18.246 13.2613C19.057 14.4083 18.989 16.0013 18.021 17.0213L17.776 17.2783C17.7753 17.2802 17.7746 17.2822 17.774 17.2843C17.761 17.3203 17.729 17.4363 17.761 17.6563C17.828 18.1113 18.179 19.0372 19.607 20.5402C21.039 22.0483 21.907 22.4033 22.31 22.4693C22.4071 22.491 22.508 22.4886 22.604 22.4623L23.012 22.0323C23.886 21.1123 25.248 20.9313 26.347 21.5633L28.257 22.6632C29.89 23.6032 30.27 25.9023 28.965 27.2763L27.545 28.7713C27.102 29.2383 26.497 29.6373 25.75 29.7113C23.926 29.8913 19.701 29.6562 15.272 24.9922C11.138 20.6412 10.353 16.8563 10.254 15.0073C10.205 14.0933 10.612 13.3103 11.148 12.7453L12.718 11.0933ZM15.761 12.3433C15.249 11.6193 14.328 11.5753 13.805 12.1263L12.235 13.7783C11.905 14.1283 11.73 14.5283 11.752 14.9273C11.832 16.4373 12.483 19.8793 16.359 23.9593C20.423 28.2393 24.168 28.3593 25.603 28.2183C25.886 28.1903 26.178 28.0323 26.457 27.7383L27.877 26.2433C28.491 25.5973 28.33 24.4353 27.509 23.9633L25.599 22.8633C25.086 22.5683 24.485 22.6593 24.1 23.0653L23.644 23.5452L23.117 23.0443C23.644 23.5443 23.644 23.5452 23.643 23.5463L23.642 23.5472L23.639 23.5513L23.632 23.5573L23.618 23.5712C23.576 23.6125 23.5305 23.65 23.482 23.6833C23.402 23.7393 23.296 23.8022 23.161 23.8553C22.885 23.9643 22.521 24.0223 22.07 23.9493C21.192 23.8073 20.042 23.1762 18.52 21.5732C16.992 19.9653 16.407 18.7663 16.277 17.8733C16.21 17.4193 16.263 17.0563 16.361 16.7813C16.4154 16.628 16.493 16.484 16.591 16.3543L16.621 16.3173L16.635 16.3023L16.641 16.2953L16.644 16.2923L16.646 16.2913C16.646 16.2913 16.646 16.2893 17.179 16.7943L16.647 16.2893L16.934 15.9873C17.379 15.5183 17.444 14.7243 17.022 14.1273L15.761 12.3433Z"
              fill={
                location.pathname == routes.contacts
                  ? "var(--color-text-two)"
                  : "var(--base-icon-color)"
              }
            />
            <path
              d="M21.2584 9.87963C21.2905 9.68333 21.3992 9.5078 21.5606 9.39157C21.7221 9.27533 21.923 9.22788 22.1194 9.25963C22.1444 9.26463 22.2264 9.27963 22.2694 9.28963C22.3544 9.30763 22.4734 9.33763 22.6214 9.37963C22.9184 9.46663 23.3334 9.60963 23.8314 9.83763C24.8274 10.2946 26.1524 11.0936 27.5284 12.4686C28.9044 13.8446 29.7034 15.1706 30.1604 16.1666C30.3884 16.6646 30.5304 17.0786 30.6174 17.3766C30.6612 17.5264 30.6989 17.6778 30.7304 17.8306L30.7354 17.8616C30.7683 18.0597 30.7221 18.2627 30.6066 18.427C30.4912 18.5912 30.3158 18.7035 30.1184 18.7396C29.9226 18.7714 29.7221 18.7244 29.561 18.6087C29.3998 18.4931 29.291 18.3183 29.2584 18.1226C29.2379 18.0121 29.2109 17.9029 29.1774 17.7956C29.0752 17.4522 28.9482 17.1167 28.7974 16.7916C28.4074 15.9416 27.7054 14.7676 26.4674 13.5296C25.2304 12.2916 24.0564 11.5906 23.2054 11.2006C22.8807 11.0499 22.5454 10.9229 22.2024 10.8206C22.0971 10.7909 21.9911 10.7643 21.8844 10.7406C21.6879 10.7079 21.5122 10.5993 21.3951 10.4383C21.278 10.2772 21.2289 10.0766 21.2584 9.87963Z"
              fill={
                location.pathname == routes.contacts
                  ? "var(--color-text-two)"
                  : "var(--base-icon-color)"
              }
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.4821 13.3299C21.5091 13.2351 21.5544 13.1466 21.6156 13.0694C21.6768 12.9922 21.7526 12.9278 21.8387 12.8798C21.9248 12.8319 22.0195 12.8014 22.1173 12.7901C22.2152 12.7788 22.3144 12.7869 22.4091 12.8139L22.2031 13.5349L22.4101 12.8149H22.4121L22.4161 12.8159L22.4231 12.8179L22.4431 12.8249C22.522 12.8513 22.5997 12.881 22.6761 12.9139C22.8221 12.9759 23.0211 13.0719 23.2661 13.2169C23.7561 13.5069 24.4231 13.9869 25.2081 14.7729C25.9931 15.5579 26.4751 16.2259 26.7641 16.7149C26.9091 16.9599 27.0051 17.1589 27.0681 17.3049C27.101 17.3813 27.1307 17.459 27.1571 17.5379L27.1631 17.5579L27.1651 17.5659L27.1661 17.5689V17.5709L26.4461 17.7779L27.1671 17.5719C27.2231 17.7629 27.2009 17.9682 27.1054 18.1428C27.01 18.3174 26.8491 18.4469 26.6581 18.5029C26.4672 18.5589 26.2618 18.5367 26.0872 18.4412C25.9126 18.3458 25.7831 18.1849 25.7271 17.9939L25.7241 17.9839C25.6538 17.8097 25.5702 17.6413 25.4741 17.4799C25.2501 17.1029 24.8481 16.5329 24.1481 15.8329C23.4481 15.1329 22.8781 14.7309 22.5021 14.5079C22.3407 14.4118 22.1723 14.3282 21.9981 14.2579L21.9881 14.2539C21.799 14.1973 21.6399 14.0685 21.5451 13.8953C21.4504 13.7222 21.4278 13.5196 21.4821 13.3299Z"
              fill={
                location.pathname == routes.contacts
                  ? "var(--color-text-two)"
                  : "var(--base-icon-color)"
              }
            />
          </svg>

          <p
            style={
              location.pathname == routes.contacts
                ? { fontWeight: "600", color: "var(--color-text-two)" }
                : {}
            }
          >
            Контакты
          </p>
        </Link>
        <Link to={routes.documents}>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13 22.25C13 22.0511 13.079 21.8603 13.2197 21.7197C13.3603 21.579 13.5511 21.5 13.75 21.5H21.75C21.9489 21.5 22.1397 21.579 22.2803 21.7197C22.421 21.8603 22.5 22.0511 22.5 22.25C22.5 22.4489 22.421 22.6397 22.2803 22.7803C22.1397 22.921 21.9489 23 21.75 23H13.75C13.5511 23 13.3603 22.921 13.2197 22.7803C13.079 22.6397 13 22.4489 13 22.25ZM13 25.75C13 25.5511 13.079 25.3603 13.2197 25.2197C13.3603 25.079 13.5511 25 13.75 25H19.25C19.4489 25 19.6397 25.079 19.7803 25.2197C19.921 25.3603 20 25.5511 20 25.75C20 25.9489 19.921 26.1397 19.7803 26.2803C19.6397 26.421 19.4489 26.5 19.25 26.5H13.75C13.5511 26.5 13.3603 26.421 13.2197 26.2803C13.079 26.1397 13 25.9489 13 25.75Z"
              fill={
                location.pathname == routes.documents
                  ? "var(--color-text-two)"
                  : "var(--base-icon-color)"
              }
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10.584C19.54 10.506 18.912 10.5 17.78 10.5C15.863 10.5 14.5 10.502 13.468 10.64C12.456 10.775 11.875 11.03 11.452 11.452C11.029 11.875 10.775 12.455 10.64 13.461C10.502 14.489 10.5 15.843 10.5 17.751V21.751C10.5 23.657 10.502 25.011 10.64 26.039C10.775 27.045 11.029 27.625 11.452 28.049C11.875 28.471 12.455 28.725 13.461 28.86C14.489 28.999 15.843 29 17.75 29H21.75C23.657 29 25.012 28.998 26.04 28.86C27.045 28.725 27.625 28.471 28.048 28.048C28.471 27.625 28.725 27.045 28.86 26.039C28.998 25.012 29 23.657 29 21.75V21.313C29 19.777 28.99 19.049 28.826 18.5H25.696C24.563 18.5 23.638 18.5 22.906 18.402C22.143 18.299 21.481 18.077 20.952 17.548C20.423 17.019 20.201 16.358 20.098 15.593C20 14.863 20 13.937 20 12.803V10.584ZM21.5 11.36V12.75C21.5 13.95 21.502 14.774 21.585 15.393C21.665 15.991 21.809 16.284 22.013 16.487C22.216 16.691 22.509 16.835 23.107 16.915C23.726 16.998 24.55 17 25.75 17H27.77C27.3863 16.6346 26.9962 16.2759 26.6 15.924L22.641 12.361C22.2675 12.0196 21.8871 11.6859 21.5 11.36ZM17.925 9C19.31 9 20.205 9 21.028 9.315C21.851 9.631 22.513 10.227 23.538 11.15L23.645 11.246L27.603 14.809L27.728 14.921C28.912 15.986 29.678 16.675 30.089 17.599C30.501 18.523 30.501 19.553 30.5 21.145V21.806C30.5 23.644 30.5 25.1 30.347 26.239C30.189 27.411 29.857 28.36 29.109 29.109C28.36 29.857 27.411 30.189 26.239 30.347C25.099 30.5 23.644 30.5 21.806 30.5H17.694C15.856 30.5 14.4 30.5 13.261 30.347C12.089 30.189 11.14 29.857 10.391 29.109C9.643 28.36 9.311 27.411 9.153 26.239C9 25.099 9 23.644 9 21.806V17.695C9 15.857 9 14.401 9.153 13.262C9.311 12.09 9.643 11.141 10.391 10.392C11.141 9.643 12.092 9.312 13.269 9.154C14.413 9.001 15.876 9.001 17.724 9.001H17.78L17.925 9Z"
              fill={
                location.pathname == routes.documents
                  ? "var(--color-text-two)"
                  : "var(--base-icon-color)"
              }
            />
          </svg>

          <p
            style={
              location.pathname == routes.documents
                ? { fontWeight: "600", color: "var(--color-text-two)" }
                : {}
            }
          >
            Документы
          </p>
        </Link>
        <Link to={routes.chat}>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 18.5H24M16 22H21.5M25 11.338C23.4805 10.4589 21.7555 9.99731 20 10C14.477 10 10 14.477 10 20C10 21.6 10.376 23.112 11.043 24.453C11.221 24.809 11.28 25.216 11.177 25.601L10.582 27.827C10.5231 28.0473 10.5231 28.2792 10.5822 28.4995C10.6412 28.7197 10.7571 28.9206 10.9183 29.0819C11.0795 29.2432 11.2803 29.3593 11.5005 29.4184C11.7208 29.4776 11.9527 29.4778 12.173 29.419L14.399 28.823C14.7854 28.7258 15.194 28.7731 15.548 28.956C16.9309 29.6446 18.4551 30.0021 20 30C25.523 30 30 25.523 30 20C30 18.179 29.513 16.47 28.662 15"
              stroke={
                location.pathname == routes.chat
                  ? "var(--color-text-two)"
                  : "var(--base-icon-color)"
              }
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>

          <p
            style={
              location.pathname == routes.chat
                ? { fontWeight: "600", color: "var(--color-text-two)" }
                : {}
            }
          >
            On-line Чат
          </p>
        </Link>
      </div>
      <div className="collapse" onClick={() => isNav(!nav)}>
        <svg
          style={!nav ? { transform: "rotate(180deg)" } : {}}
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 20L18 20M18 20L21 23M18 20L21 17"
            stroke="var(--base-icon-color)"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15 24L15 16M20 30C15.286 30 12.929 30 11.465 28.535C10 27.072 10 24.714 10 20C10 15.286 10 12.929 11.465 11.464C12.928 10 15.286 10 20 10C24.714 10 27.071 10 28.536 11.464C30 12.93 30 15.286 30 20C30 24.714 30 27.071 28.536 28.535C27.562 29.509 26.193 29.835 24 29.945"
            stroke="var(--base-icon-color)"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>

        <p>Свернуть</p>
      </div>
    </div>
  );
}
