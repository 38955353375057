import { useContext, useState } from "react";
import CardList from "../../Containers/CardList";
import "./style.css";
import { useEffect } from "react";
import ThemeCont from "../../Components/Theme";
import ServiceSearch from "../../Components/ServiceSearch";
import Filters from "../../Components/Filters";
import RightPage from "../../Components/RightPage";
import { DataMain } from "../../Contexts/Data";
export default function Services({ setSearch, search }) {
  const [data, setData] = useContext(DataMain);

  return (
    <div className="services">
      <div className="serviceBody">
        <ServiceSearch setSearch={setSearch} search={search}></ServiceSearch>
        <CardList search={search} list={data.dataM} width={17}></CardList>
      </div>
      <Filters></Filters>
      {document.body.clientWidth < 640 && <RightPage></RightPage>}
    </div>
  );
}
