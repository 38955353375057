import RightPage from "../../Components/RightPage";
import crug from "./img/crug.png";
import ava from "./img/1.png";
import "./style.css";
import { useEffect, useRef, useState } from "react";
import av from "./img/av.png";
import { getChat, sendMessage } from "../../Api/ApiGet";
import { useContext } from "react";
import { User } from "../../Contexts/User";
export default function Chat() {
  const [chat, setChat] = useState([]);
  const [message, setMessage] = useState("");
  const [user, setUser] = useContext(User);
  const chatt = useRef(null);
  const inp = useRef();
  useEffect(() => {
    getChat().then((data) => {
      setChat(data);
    });
    const interval = setInterval(() => {
      getChat().then((data) => {
        setChat(data);
      });
    }, 3000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (chatt && chatt.current) {
      console.log(chatt.current.scrollHeight);
      chatt.current.scrollTop = chatt.current.scrollHeight;
    }
  }, [chat]);
  return (
    <div className="Page chatPage">
      <div className="companyBody">
        <h2>ОНЛАЙН - ЧАТ</h2>
        <div
          className="chatHellp"
          style={chat.length > 0 ? { display: "none" } : {}}
        >
          <img src={crug} alt="" />
          <div>
            <p>Нужна помощь?</p>
            <span>
              Напишите нам в чат, и наш менеджер с радостью ответит на ваши
              вопросы!
            </span>
          </div>
        </div>
        <div className="mess" ref={chatt}>
          <div className="manager">
            <div className="managerEm">
              <img src={ava} alt="" />
              <p>Алена</p>
            </div>
            <div className="question">
              <p> Я рада приветствовать вас в онлайн-чате!</p>
              Готова ответить на ваши вопросы и помочь вам с выбором продукции.
              <br /> Пожалуйста, не стесняйтесь спрашивать!
            </div>
          </div>
          <div className="chatM">
            {chat.length > 0 &&
              chat.map((em, index) => {
                if (em.Bot == "1") {
                  return (
                    <div
                      className="manager manageYourSelf"
                      key={index}
                      style={{ flexDirection: "row" }}
                    >
                      <div className="managerEm">
                        <img src={ava} alt="" />
                        <p>Алена</p>
                      </div>
                      <div className="question">{em.textMessage}</div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="manager manageYourSelf"
                      key={index}
                      style={{ flexDirection: "row-reverse" }}
                    >
                      <div className="managerEm">
                        <img src={user.Photo || av} alt="" />
                        <p>Вы</p>
                      </div>
                      <div className="question">{em.textMessage}</div>
                    </div>
                  );
                }
              })}
          </div>
        </div>
        <div className="inputQuestion">
          <input
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                sendMessage(message).then((resp) => {
                  if (resp == 200) {
                    getChat().then((data) => {
                      setChat(data);
                    });
                    setMessage("");
                  }
                });
              }
            }}
            type="text"
            ref={inp}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Введите вопрос..."
            name=""
            id="ara"
          />
          <div
            className="sendMessage"
            onClick={() => {
              sendMessage(message).then((resp) => {
                if (resp == 200) {
                  getChat().then((data) => {
                    setChat(data);
                  });
                  setMessage("");
                }
              });
            }}
          >
            Отправить
          </div>
        </div>
      </div>
      <RightPage></RightPage>
    </div>
  );
}
