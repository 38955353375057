import { useContext, useEffect, useRef, useState } from "react";
import { User } from "../../Contexts/User";
import { Store } from "react-notifications-component";
import ThemeCont from "../../Components/Theme";
import { Link } from "react-router-dom";
import { routes } from "../../routes";
import { DataMain } from "../../Contexts/Data";
import { Theme } from "../../Contexts/Theme";
import robot from "../Main/img/robot.png";
import loop from "../../Icons/Loop.svg";
import "./style.css";
import RightPage from "../../Components/RightPage";
import { getHistoryOrders, uploadAvatar } from "../../Api/ApiGet";
import { ImgCard } from "./ImgCard";
import userAva from "./img/avatar.svg";
export default function Profile({ setSearch, search }) {
  const [user, setUser] = useContext(User);
  const [theme, setTheme] = useContext(Theme);
  const [data, setData] = useState([]);
  const [orders, setOrders] = useState();
  const [stocks, setStocks] = useContext(DataMain);
  const [voices, setVoice] = useState("");
  const file = useRef(null);
  useEffect(() => {
    getHistoryOrders(user.Phone).then((data) => {
      setOrders(data);
    });
  }, []);
  useEffect(() => {
    localStorage.user = JSON.stringify(user);
  }, [user]);
  useEffect(() => {
    setSearch(voices);
  }, [voices]);
  function listen() {
    let recognition = new window.webkitSpeechRecognition();
    recognition.lang = "ru-RU";
    recognition.interimResults = true;
    recognition.start();

    recognition.onresult = function (event) {
      event.results[0][0] &&
        event.results[0][0].transcript &&
        setVoice(event.results[0][0].transcript);
    };
  }
  function setNotification(text) {
    Store.addNotification({
      title: "Профиль",
      message: text,
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__bounceIn"],
      animationOut: ["animate__animated", "animate__backOutUp"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  }
  return (
    <div className="profile">
      <div className="profLeft">
        <div className="headerMain searchInput">
          <ThemeCont></ThemeCont>
          <div className="loopInput" style={{ background: theme.searchColor }}>
            <input
              type="text"
              placeholder="Поиск..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <img src={loop} alt="" />
          </div>
          <Link
            onClick={() => {
              Store.addNotification({
                title: "Ai ассистент:",
                message: `Продиктуйте мне фразу для поиска товаров`,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__bounceIn"],
                animationOut: ["animate__animated", "animate__backOutUp"],
                dismiss: {
                  duration: 5000,
                  onScreen: true,
                },
              });
              listen();
            }}
            className="robot"
          >
            <img src={robot} alt="" /> Ai ассистент
          </Link>
          {document.body.clientWidth > 1500 && (
            <Link to={routes.services}>Перейти в Каталог</Link>
          )}
        </div>
        {user && (
          <div className="profileBody Page">
            <h3>Профиль</h3>
            <div className="profileAccount">
              <div className="userOrders"></div>
              <div className="userData">
                <img
                  src={user.Photo.length != 0 ? user.Photo : userAva}
                  alt=""
                />
                <label htmlFor="img">
                  <div className="userBtn">Изменить</div>
                </label>

                <input
                  id="img"
                  style={{ display: "none" }}
                  type="file"
                  ref={file}
                  onChange={() =>
                    uploadAvatar(file.current.files[0]).then((resp) => {
                      setUser((userData) => ({ ...userData, Photo: resp }));
                      setNotification("Фото успешно обновлено");
                    })
                  }
                />
                <p>Мои данные</p>
                <h3>{user.FIO}</h3>
                <span>{user.Phone}</span>
                <span>{user.Email}</span>
              </div>
            </div>
            <h3>История заказов</h3>
            {orders && orders.length == 0 && (
              <p className="zakaz">У вас пока не было заказов</p>
            )}
            <div className="historyOrders">
              {orders &&
                orders.length > 0 &&
                orders.map((em, index) => {
                  if (index < 2) {
                    const details = JSON.parse(em.Positions);
                    return (
                      <div className="historyOrderItem">
                        <h4>Заказ № {em.ID}</h4>

                        <div className="historyOrderImg">
                          {details.map((em, index) => {
                            return (
                              <div
                                style={{
                                  transform: `translateX(${-50 * index}px)`,
                                }}
                              >
                                <ImgCard
                                  key={`img${index}`}
                                  id={em.id}
                                ></ImgCard>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        )}
      </div>
      <div className="profRight">
        <RightPage></RightPage>
      </div>
    </div>
  );
}
