export const routes = {
  main: "/",
  services: "/services",
  basket: "/basket",
  about: "/about",
  contacts: "/contacts",
  documents: "/documents",
  chat: "/chat",
  cardPage: "/card/:id",
  login: "/login",
  profile: "/profile",
  slider: "/niches",
  registration: "/registration",
  model: "/model",
};
