import { useContext, useEffect, useState } from "react";
import { BusketV } from "../../../Contexts/Busket";
import "./style.css";
import PopUp from "../../PopUp";
import close from "../../PopUp/close.svg";
import busketDelete from "../../../Icons/BasketDelete.svg";
import { Rotate } from "react-reveal";
import { routes } from "../../../routes";
import { useNavigate } from "react-router-dom";
export default function BusketEm({ em, img }) {
  const [num, setNum] = useState(em.Quantity != null ? em.Quantity : 1);
  const [busket, setBusket] = useContext(BusketV);
  const [pop, setPop] = useState(false);
  const [deleteItem, isDelete] = useState(false);
  const [check, setCheck] = useState(em.Property != "" ? em.Property : true);
  const Navigate = useNavigate();
  useEffect(() => {
    setBusket(
      busket.map((elem) =>
        elem.ID === em.ID ? { ...elem, Quantity: num } : elem
      )
    );
  }, [num]);
  useEffect(() => {
    setBusket(
      busket.map((elem) =>
        elem.ID === em.ID ? { ...elem, Property: check } : elem
      )
    );
  }, [check]);
  const handleRemoveItem = () => {
    setBusket((l) => l.filter((item) => item.ID !== em.ID));
    setPop(false);
  };
  return (
    <div className="busketEm">
      {pop && (
        <div className="popUp">
          <div className="popBody">
            <img src={close} alt="" onClick={() => setPop(false)} />
            <p>
              Вы уверены, что <br /> хотите удалить товар?
            </p>
            <div className="popButtons">
              <div className="popAgree" onClick={() => handleRemoveItem()}>
                Удалить
              </div>
              <div className="popBack" onClick={() => setPop(false)}>
                Отмена
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        onClick={() => {
          setBusket(
            busket.map((elem) =>
              elem.ID === em.ID ? { ...elem, Property: !em.Property } : elem
            )
          );
        }}
        className={em.Property ? "checkAct" : "checkFor"}
      ></div>
      <Rotate top duration={500}>
        <img src={img[0]} alt="" onClick={() => Navigate(`/card/${em.ID}`)} />
      </Rotate>
      <div className="summBusket">
        <article>
          <p onClick={() => Navigate(`/card/${em.ID}`)}>{em.Title}</p>
          <div className="prBusket">
            {em.PriceOld != "undefined" && <b> {em.PriceOld * num}</b>}
            <span>{em.Price * num + " руб"}</span>
          </div>
        </article>
        <div className="addSome">
          <p>Количество</p>
          <h4>
            <span
              onClick={() => num != 1 && setNum(num - 1)}
              style={num != 1 ? { background: "#FF7A00", color: "#fff" } : {}}
            >
              -
            </span>
            <div>{em.Quantity != null ? em.Quantity : "1"}</div>
            <span
              style={{ background: "var(--color-text-two)", color: "#fff" }}
              onClick={() => setNum(num + 1)}
            >
              +
            </span>
            {document.body.clientWidth < 640 && (
              <img
                className="bsd"
                src={busketDelete}
                onClick={() => {
                  setPop(true);
                }}
              ></img>
            )}
          </h4>
          <article>В наличии: в 3 магазинах</article>
          <button
            onClick={() => {
              setPop(true);
            }}
          >
            Удалить
          </button>
        </div>
      </div>
    </div>
  );
}
