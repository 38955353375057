import "./style.css";
import RightPage from "../../Components/RightPage";
import { routes } from "../../routes";
import { Link, useSearchParams } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";

import gal from "./img/gal.svg";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { id } from "../../Consts/Const";
// import required modules
import { Pagination } from "swiper/modules";
import { Fade, Zoom } from "react-reveal";
import { useContext, useEffect, useState } from "react";
import { Theme } from "../../Contexts/Theme";
const txt = [
  [
    "Малахитовая шкатулка",
    "Это молодой, но амбициозный бренд, созданный командой профессионалов, влюбленных в янтарь. Мы верим, что янтарь – это не просто камень, а настоящее произведение искусства, созданное самой природой.",
    "Янтарь: 40 миллионов лет, отпечатанных в камне.",
    "Именно столько понадобилось, чтобы стать вашим идеальным украшением.",
    "Качество",
    "Стиль",
    "Доступность",
    "Индивидуальность",
    "Используем только высококачественные материалы и фурнитуру",
    "Следим за модными трендами и предлагаем актуальные украшения",
    "Хотим, чтобы красота янтаря была доступной для каждого",
    "Ценим наших клиентов и предлагаем уникальные украшения",
  ],
  [
    "Электроникс",
    "Мы верим, что электроника — это не просто устройства, а инструменты, расширяющие границы возможного. От смартфонов до ноутбуков и аудиосистем — каждое наше изделие создано для того, чтобы сделать вашу жизнь удобнее и продуктивнее.",
    "Технологии: ваш мир к  кармане.",
    "Современные устройства — это годы инноваций, воплощённые в идеальном балансе мощности и комфорта. С нами вы всегда на шаг впереди.",
    "Качество",
    "Стиль",
    "Доступность",
    "Индивидуальность",
    "Только высококачественные комплектующие, гарантирующие  долговечность.",
    "Следим за последними трендами и предлагаем современные устройства с элегантным дизайном.",
    "Хотим, чтобы технологии были доступны каждому.",
    "Ориентируемся на ваши потребности и предлагаем лучшие решения",
  ],
  [
    "URBAN ROOTS",
    "Современный ресторан азиатской кухни, где традиционные вкусы Востока встречаются с креативным подходом и уникальной подачей. Мы объединяем насыщенные ароматы, свежие ингредиенты и мастерство наших поваров, чтобы каждое блюдо стало для вас настоящим гастрономическим приключением. Добро пожаловать в место, где Азия оживает в каждом вкусовом оттенке.",
    "Азия на вашей тарелке",
    "Мы создаем вкусовые сочетания, которые перенесут вас на оживленные улицы Токио, Гонконга и Бангкока, не покидая вашего города. Позвольте нам удивить вас истинным духом Востока!",
    "Качество",
    "Стиль",
    "Доступность",
    "Индивидуальность",
    "Используем только свежие  ингредиенты, чтобы каждое блюдо дарило вам настоящий вкус Азии.",
    "Сочетаем традиционные рецепты с современными тенденциями, создавая уникальные авторские блюда и подачу.",
    "Предлагаем разнообразное меню с блюдами на любой бюджет.",
    "Любите поострее? Без проблем! Наша кухня всегда готова удивлять.",
  ],
];
export default function Company() {
  const [theme, setTheme] = useContext(Theme);
  const [ind, setInd] = useState(0);
  useEffect(() => {
    if (id == 23) {
      setInd(0);
    }
    if (id == 27) {
      setInd(1);
    }
    if (id == 28) {
      setInd(2);
    }
  }, []);
  return (
    <div className="Page">
      <div className="companyBody">
        <h2>О КОМПАНИИ</h2>
        <div className="flexHeight">
          <div className="gr" style={{ boxShadow: theme.shadowCard }}>
            <img src={"./comp/" + id + "/oz.png"} alt="" />
            <div>
              <p>{txt[ind][0]}</p>
              <span>{txt[ind][1]}</span>
            </div>
          </div>
          <h3>О продукте</h3>

          <div className="aboutProduct">
            <b>
              <span> {txt[ind][2]}</span>{" "}
              {document.body.clientWidth > 640 && <br />}
            </b>
            {txt[ind][3]}
          </div>
          <Swiper
            slidesPerView={document.body.clientWidth > 640 ? 3 : 1}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src={"./comp/" + id + "/1.png"} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={"./comp/" + id + "/2.png"} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={"./comp/" + id + "/3.png"} alt="" />
            </SwiperSlide>
          </Swiper>
          <Zoom duration={500}>
            <img src={"./comp/" + id + "/big.png"} className="bigComp" alt="" />
          </Zoom>
          <h2>Наши ценности</h2>
          {document.body.clientWidth < 640 ? (
            <Swiper
              slidesPerView={document.body.clientWidth > 640 ? 3 : 1}
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="cennEm">
                  <img src={"./comp/" + id + "/11.png"} alt="" />
                  <p>Качество</p>
                  <span>{txt[ind][8]}</span>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="cennEm">
                  <img src={"./comp/" + id + "/22.png"} alt="" />
                  <p>Стиль</p>
                  <span>{txt[ind][9]}</span>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div className="cennEm">
                  <img src={"./comp/" + id + "/33.png"} alt="" />
                  <p>Доступность</p>
                  <span>{txt[ind][10]}</span>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div className="cennEm">
                  <img src={"./comp/" + id + "/44.png"} alt="" />
                  <p>Индивидуальность</p>
                  <span>{txt[ind][11]}</span>
                </div>
              </SwiperSlide>
            </Swiper>
          ) : (
            <div className="cenn">
              <div className="cennEm">
                <img src={"./comp/" + id + "/11.png"} alt="" />
                <p>Качество</p>
                <span>{txt[ind][8]}</span>
              </div>
              <div className="cennEm">
                <img src={"./comp/" + id + "/22.png"} alt="" />
                <p>Стиль</p>
                <span>{txt[ind][9]}</span>
              </div>
              <div className="cennEm">
                <img src={"./comp/" + id + "/33.png"} alt="" />
                <p>Доступность</p>
                <span>{txt[ind][10]}</span>
              </div>
              <div className="cennEm">
                <img src={"./comp/" + id + "/44.png"} alt="" />
                <p>Индивидуальность</p>
                <span>{txt[ind][11]}</span>
              </div>
            </div>
          )}
          <ul className="gals">
            <Fade left duration={500} delay={0}>
              <li>
                <img src={gal} alt="" />
                <p>Предлагаем гибкую систему скидок для постоянных клиентов</p>
              </li>
            </Fade>
            <Fade left duration={500} delay={100}>
              <li>
                <img src={gal} alt="" />
                <p>Накопительная бонусная система</p>
              </li>
            </Fade>
            <Fade left duration={500} delay={200}>
              <li>
                <img src={gal} alt="" />
                <p>Регулярно проводим акции и розыгрыши</p>
              </li>
            </Fade>
          </ul>
          <Fade duration={500} bottom>
            <Link to={routes.services} className="button">
              Перейти в каталог
            </Link>
          </Fade>
          <p className="txtCom">
            <b>Мы всегда рады</b> новым клиентам и партнерам!
          </p>
        </div>
      </div>
      <RightPage></RightPage>
    </div>
  );
}
