import { Link } from "react-router-dom";
import "./style.css";
import img1 from "./img/1.svg";
import img2 from "./img/2.svg";
import img3 from "./img/3.svg";
import img4 from "./img/4.svg";
import { routes } from "../../routes";
export default function MobileLinks() {
  return (
    <div className="mobileLinks">
      <Link to={routes.services}>
        <img src={img1} alt="" />
        <p>Каталог</p>
      </Link>
      <Link to={routes.about}>
        <img src={img2} alt="" />
        <p>О компании</p>
      </Link>
      <Link to={routes.contacts}>
        <img src={img3} alt="" />
        <p>Контакты</p>
      </Link>
      <Link to={routes.documents}>
        <img src={img4} alt="" />
        <p>Документы</p>
      </Link>
    </div>
  );
}
