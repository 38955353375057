import RightPage from "../../Components/RightPage";
import "./style.css";
import doc from "./img/doc.svg";
import { Fade } from "react-reveal";
export default function Documents() {
  return (
    <div className="Page">
      <div className="contactBody">
        <h2>ДОКУМЕНТЫ</h2>
        <Fade right duration={500}>
          <div className="docEm">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="50"
                height="50"
                rx="5"
                fill="var(--color-text-two)
"
              />
              <path
                d="M18.3359 9.83593C18.3359 9.17289 18.5993 8.53701 19.0682 8.06817C19.537 7.59933 20.1729 7.33594 20.8359 7.33594H29.1692C29.8323 7.33594 30.4682 7.59933 30.937 8.06817C31.4058 8.53701 31.6692 9.17289 31.6692 9.83593V11.5026C31.6692 12.1656 31.4058 12.8015 30.937 13.2704C30.4682 13.7392 29.8323 14.0026 29.1692 14.0026H20.8359C20.1729 14.0026 19.537 13.7392 19.0682 13.2704C18.5993 12.8015 18.3359 12.1656 18.3359 11.5026V9.83593Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.8333 10.7344C13.7367 10.851 12.4133 11.1844 11.465 12.1344C10 13.5994 10 15.956 10 20.6693V30.6693C10 35.3843 10 37.741 11.465 39.206C12.9283 40.6693 15.2867 40.6693 20 40.6693H29.9999C34.7133 40.6693 37.0716 40.6693 38.5349 39.206C39.9999 37.7393 39.9999 35.3843 39.9999 30.6693V20.6693C39.9999 15.956 39.9999 13.5994 38.5349 12.1344C37.5866 11.1844 36.2633 10.851 34.1666 10.7344V11.506C34.1666 12.8321 33.6398 14.1039 32.7021 15.0416C31.7645 15.9792 30.4927 16.506 29.1666 16.506H20.8333C19.5072 16.506 18.2355 15.9792 17.2978 15.0416C16.3601 14.1039 15.8333 12.8321 15.8333 11.506V10.7344ZM15.4167 21.506C15.4167 21.1745 15.5483 20.8565 15.7828 20.6221C16.0172 20.3877 16.3351 20.256 16.6666 20.256H33.3333C33.6648 20.256 33.9827 20.3877 34.2171 20.6221C34.4516 20.8565 34.5833 21.1745 34.5833 21.506C34.5833 21.8375 34.4516 22.1555 34.2171 22.3899C33.9827 22.6243 33.6648 22.756 33.3333 22.756H16.6666C16.3351 22.756 16.0172 22.6243 15.7828 22.3899C15.5483 22.1555 15.4167 21.8375 15.4167 21.506ZM17.0833 27.3393C17.0833 27.0078 17.215 26.6899 17.4494 26.4554C17.6838 26.221 18.0018 26.0893 18.3333 26.0893H31.6666C31.9981 26.0893 32.3161 26.221 32.5505 26.4554C32.7849 26.6899 32.9166 27.0078 32.9166 27.3393C32.9166 27.6708 32.7849 27.9888 32.5505 28.2232C32.3161 28.4576 31.9981 28.5893 31.6666 28.5893H18.3333C18.0018 28.5893 17.6838 28.4576 17.4494 28.2232C17.215 27.9888 17.0833 27.6708 17.0833 27.3393ZM18.75 33.1726C18.75 32.8411 18.8817 32.5232 19.1161 32.2888C19.3505 32.0543 19.6685 31.9226 20 31.9226H29.9999C30.3315 31.9226 30.6494 32.0543 30.8838 32.2888C31.1182 32.5232 31.2499 32.8411 31.2499 33.1726C31.2499 33.5042 31.1182 33.8221 30.8838 34.0565C30.6494 34.2909 30.3315 34.4226 29.9999 34.4226H20C19.6685 34.4226 19.3505 34.2909 19.1161 34.0565C18.8817 33.8221 18.75 33.5042 18.75 33.1726Z"
                fill="white"
              />
            </svg>
            <p>Политика конфиденциальности</p>
            <a href="#">Открыть</a>
          </div>
        </Fade>
        <Fade right duration={500} delay={100}>
          <div className="docEm">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="50"
                height="50"
                rx="5"
                fill="var(--color-text-two)
"
              />
              <path
                d="M18.3359 9.83593C18.3359 9.17289 18.5993 8.53701 19.0682 8.06817C19.537 7.59933 20.1729 7.33594 20.8359 7.33594H29.1692C29.8323 7.33594 30.4682 7.59933 30.937 8.06817C31.4058 8.53701 31.6692 9.17289 31.6692 9.83593V11.5026C31.6692 12.1656 31.4058 12.8015 30.937 13.2704C30.4682 13.7392 29.8323 14.0026 29.1692 14.0026H20.8359C20.1729 14.0026 19.537 13.7392 19.0682 13.2704C18.5993 12.8015 18.3359 12.1656 18.3359 11.5026V9.83593Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.8333 10.7344C13.7367 10.851 12.4133 11.1844 11.465 12.1344C10 13.5994 10 15.956 10 20.6693V30.6693C10 35.3843 10 37.741 11.465 39.206C12.9283 40.6693 15.2867 40.6693 20 40.6693H29.9999C34.7133 40.6693 37.0716 40.6693 38.5349 39.206C39.9999 37.7393 39.9999 35.3843 39.9999 30.6693V20.6693C39.9999 15.956 39.9999 13.5994 38.5349 12.1344C37.5866 11.1844 36.2633 10.851 34.1666 10.7344V11.506C34.1666 12.8321 33.6398 14.1039 32.7021 15.0416C31.7645 15.9792 30.4927 16.506 29.1666 16.506H20.8333C19.5072 16.506 18.2355 15.9792 17.2978 15.0416C16.3601 14.1039 15.8333 12.8321 15.8333 11.506V10.7344ZM15.4167 21.506C15.4167 21.1745 15.5483 20.8565 15.7828 20.6221C16.0172 20.3877 16.3351 20.256 16.6666 20.256H33.3333C33.6648 20.256 33.9827 20.3877 34.2171 20.6221C34.4516 20.8565 34.5833 21.1745 34.5833 21.506C34.5833 21.8375 34.4516 22.1555 34.2171 22.3899C33.9827 22.6243 33.6648 22.756 33.3333 22.756H16.6666C16.3351 22.756 16.0172 22.6243 15.7828 22.3899C15.5483 22.1555 15.4167 21.8375 15.4167 21.506ZM17.0833 27.3393C17.0833 27.0078 17.215 26.6899 17.4494 26.4554C17.6838 26.221 18.0018 26.0893 18.3333 26.0893H31.6666C31.9981 26.0893 32.3161 26.221 32.5505 26.4554C32.7849 26.6899 32.9166 27.0078 32.9166 27.3393C32.9166 27.6708 32.7849 27.9888 32.5505 28.2232C32.3161 28.4576 31.9981 28.5893 31.6666 28.5893H18.3333C18.0018 28.5893 17.6838 28.4576 17.4494 28.2232C17.215 27.9888 17.0833 27.6708 17.0833 27.3393ZM18.75 33.1726C18.75 32.8411 18.8817 32.5232 19.1161 32.2888C19.3505 32.0543 19.6685 31.9226 20 31.9226H29.9999C30.3315 31.9226 30.6494 32.0543 30.8838 32.2888C31.1182 32.5232 31.2499 32.8411 31.2499 33.1726C31.2499 33.5042 31.1182 33.8221 30.8838 34.0565C30.6494 34.2909 30.3315 34.4226 29.9999 34.4226H20C19.6685 34.4226 19.3505 34.2909 19.1161 34.0565C18.8817 33.8221 18.75 33.5042 18.75 33.1726Z"
                fill="white"
              />
            </svg>

            <p>Пользовательское соглашение</p>
            <a href="#">Открыть</a>
          </div>
        </Fade>
        <Fade right duration={500} delay={200}>
          <div className="docEm">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="50"
                height="50"
                rx="5"
                fill="var(--color-text-two)
"
              />
              <path
                d="M18.3359 9.83593C18.3359 9.17289 18.5993 8.53701 19.0682 8.06817C19.537 7.59933 20.1729 7.33594 20.8359 7.33594H29.1692C29.8323 7.33594 30.4682 7.59933 30.937 8.06817C31.4058 8.53701 31.6692 9.17289 31.6692 9.83593V11.5026C31.6692 12.1656 31.4058 12.8015 30.937 13.2704C30.4682 13.7392 29.8323 14.0026 29.1692 14.0026H20.8359C20.1729 14.0026 19.537 13.7392 19.0682 13.2704C18.5993 12.8015 18.3359 12.1656 18.3359 11.5026V9.83593Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.8333 10.7344C13.7367 10.851 12.4133 11.1844 11.465 12.1344C10 13.5994 10 15.956 10 20.6693V30.6693C10 35.3843 10 37.741 11.465 39.206C12.9283 40.6693 15.2867 40.6693 20 40.6693H29.9999C34.7133 40.6693 37.0716 40.6693 38.5349 39.206C39.9999 37.7393 39.9999 35.3843 39.9999 30.6693V20.6693C39.9999 15.956 39.9999 13.5994 38.5349 12.1344C37.5866 11.1844 36.2633 10.851 34.1666 10.7344V11.506C34.1666 12.8321 33.6398 14.1039 32.7021 15.0416C31.7645 15.9792 30.4927 16.506 29.1666 16.506H20.8333C19.5072 16.506 18.2355 15.9792 17.2978 15.0416C16.3601 14.1039 15.8333 12.8321 15.8333 11.506V10.7344ZM15.4167 21.506C15.4167 21.1745 15.5483 20.8565 15.7828 20.6221C16.0172 20.3877 16.3351 20.256 16.6666 20.256H33.3333C33.6648 20.256 33.9827 20.3877 34.2171 20.6221C34.4516 20.8565 34.5833 21.1745 34.5833 21.506C34.5833 21.8375 34.4516 22.1555 34.2171 22.3899C33.9827 22.6243 33.6648 22.756 33.3333 22.756H16.6666C16.3351 22.756 16.0172 22.6243 15.7828 22.3899C15.5483 22.1555 15.4167 21.8375 15.4167 21.506ZM17.0833 27.3393C17.0833 27.0078 17.215 26.6899 17.4494 26.4554C17.6838 26.221 18.0018 26.0893 18.3333 26.0893H31.6666C31.9981 26.0893 32.3161 26.221 32.5505 26.4554C32.7849 26.6899 32.9166 27.0078 32.9166 27.3393C32.9166 27.6708 32.7849 27.9888 32.5505 28.2232C32.3161 28.4576 31.9981 28.5893 31.6666 28.5893H18.3333C18.0018 28.5893 17.6838 28.4576 17.4494 28.2232C17.215 27.9888 17.0833 27.6708 17.0833 27.3393ZM18.75 33.1726C18.75 32.8411 18.8817 32.5232 19.1161 32.2888C19.3505 32.0543 19.6685 31.9226 20 31.9226H29.9999C30.3315 31.9226 30.6494 32.0543 30.8838 32.2888C31.1182 32.5232 31.2499 32.8411 31.2499 33.1726C31.2499 33.5042 31.1182 33.8221 30.8838 34.0565C30.6494 34.2909 30.3315 34.4226 29.9999 34.4226H20C19.6685 34.4226 19.3505 34.2909 19.1161 34.0565C18.8817 33.8221 18.75 33.5042 18.75 33.1726Z"
                fill="white"
              />
            </svg>

            <p>Лицензия на торговлю изделиями из янтаря</p>
            <a href="#">Открыть</a>
          </div>
        </Fade>
        <Fade right duration={500} delay={300}>
          <div className="docEm">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="50"
                height="50"
                rx="5"
                fill="var(--color-text-two)
"
              />
              <path
                d="M18.3359 9.83593C18.3359 9.17289 18.5993 8.53701 19.0682 8.06817C19.537 7.59933 20.1729 7.33594 20.8359 7.33594H29.1692C29.8323 7.33594 30.4682 7.59933 30.937 8.06817C31.4058 8.53701 31.6692 9.17289 31.6692 9.83593V11.5026C31.6692 12.1656 31.4058 12.8015 30.937 13.2704C30.4682 13.7392 29.8323 14.0026 29.1692 14.0026H20.8359C20.1729 14.0026 19.537 13.7392 19.0682 13.2704C18.5993 12.8015 18.3359 12.1656 18.3359 11.5026V9.83593Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.8333 10.7344C13.7367 10.851 12.4133 11.1844 11.465 12.1344C10 13.5994 10 15.956 10 20.6693V30.6693C10 35.3843 10 37.741 11.465 39.206C12.9283 40.6693 15.2867 40.6693 20 40.6693H29.9999C34.7133 40.6693 37.0716 40.6693 38.5349 39.206C39.9999 37.7393 39.9999 35.3843 39.9999 30.6693V20.6693C39.9999 15.956 39.9999 13.5994 38.5349 12.1344C37.5866 11.1844 36.2633 10.851 34.1666 10.7344V11.506C34.1666 12.8321 33.6398 14.1039 32.7021 15.0416C31.7645 15.9792 30.4927 16.506 29.1666 16.506H20.8333C19.5072 16.506 18.2355 15.9792 17.2978 15.0416C16.3601 14.1039 15.8333 12.8321 15.8333 11.506V10.7344ZM15.4167 21.506C15.4167 21.1745 15.5483 20.8565 15.7828 20.6221C16.0172 20.3877 16.3351 20.256 16.6666 20.256H33.3333C33.6648 20.256 33.9827 20.3877 34.2171 20.6221C34.4516 20.8565 34.5833 21.1745 34.5833 21.506C34.5833 21.8375 34.4516 22.1555 34.2171 22.3899C33.9827 22.6243 33.6648 22.756 33.3333 22.756H16.6666C16.3351 22.756 16.0172 22.6243 15.7828 22.3899C15.5483 22.1555 15.4167 21.8375 15.4167 21.506ZM17.0833 27.3393C17.0833 27.0078 17.215 26.6899 17.4494 26.4554C17.6838 26.221 18.0018 26.0893 18.3333 26.0893H31.6666C31.9981 26.0893 32.3161 26.221 32.5505 26.4554C32.7849 26.6899 32.9166 27.0078 32.9166 27.3393C32.9166 27.6708 32.7849 27.9888 32.5505 28.2232C32.3161 28.4576 31.9981 28.5893 31.6666 28.5893H18.3333C18.0018 28.5893 17.6838 28.4576 17.4494 28.2232C17.215 27.9888 17.0833 27.6708 17.0833 27.3393ZM18.75 33.1726C18.75 32.8411 18.8817 32.5232 19.1161 32.2888C19.3505 32.0543 19.6685 31.9226 20 31.9226H29.9999C30.3315 31.9226 30.6494 32.0543 30.8838 32.2888C31.1182 32.5232 31.2499 32.8411 31.2499 33.1726C31.2499 33.5042 31.1182 33.8221 30.8838 34.0565C30.6494 34.2909 30.3315 34.4226 29.9999 34.4226H20C19.6685 34.4226 19.3505 34.2909 19.1161 34.0565C18.8817 33.8221 18.75 33.5042 18.75 33.1726Z"
                fill="white"
              />
            </svg>

            <p>Лицензия на торговлю изделиями из драгоценных металлов</p>
            <a href="#">Открыть</a>
          </div>
        </Fade>
      </div>
      <RightPage></RightPage>
    </div>
  );
}
