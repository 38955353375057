import "./style.css";
import i1 from "./img/1.svg";
import i2 from "./img/2.svg";
import i3 from "./img/3.svg";
import i4 from "./img/4.svg";
import i5 from "./img/5.svg";
import arr from "./img/arr.svg";
export default function ListRightPage() {
  return (
    <div className="listRightPage">
      <div
        className="listRightPageEm"
        style={{
          background:
            "linear-gradient(269.87deg, #7D411F -2.33%, #A3582E 99.9%)",
        }}
      >
        <img src={i1} alt="" />
        <p>FAQ</p>
        <span>Перейти</span>
        <img src={arr} alt="" />
      </div>
      <div
        className="listRightPageEm"
        style={{
          background:
            "linear-gradient(100.77deg, #B86B4A -2.8%, #692719 104.61%)",
        }}
      >
        <img src={i2} alt="" />
        <p>Сертификаты</p>
        <span>Перейти</span>
        <img src={arr} alt="" />
      </div>
      <div
        className="listRightPageEm"
        style={{
          background:
            "linear-gradient(100.48deg, #FF8934 -2.8%, #FE6332 49.42%, #B42B00 115.41%)",
        }}
      >
        <img src={i3} alt="" />
        <p>Распродажи</p>
        <span>Перейти</span>
        <img src={arr} alt="" />
      </div>
      <div
        className="listRightPageEm"
        style={{
          background:
            "linear-gradient(100.77deg, #F8A85F -2.8%, #FF7A00 104.61%)",
        }}
      >
        <img src={i4} alt="" />
        <p>Отзывы</p>
        <span>Перейти</span>
        <img src={arr} alt="" />
      </div>
      <div
        className="listRightPageEm"
        style={{
          background:
            "linear-gradient(100.77deg, #FFC32B -2.8%, #FFA826 52.74%, #FD8900 104.61%)",
        }}
      >
        <img src={i4} alt="" />
        <p>Наш блог</p>
        <span>Перейти</span>
        <img src={arr} alt="" />
      </div>
    </div>
  );
}
