import { useEffect, useState } from "react";
import { getCard } from "../../Api/ApiGet";

export function ImgCard({ id }) {
  useEffect(() => {
    getCard(id).then((data) => {
      setImg(data[0].Photo.split(" ")[0]);
    });
  }, []);
  const [img, setImg] = useState();
  return <img src={img} alt="" />;
}
