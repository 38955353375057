import { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { DataMain } from "../../Contexts/Data";
import Card from "../Card";
import { Keyboard, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.css";
import { Nav } from "../../Contexts/Nav";
export default function LooksLike({ type }) {
  const [list, setList] = useContext(DataMain);

  const [nav, isNav] = useContext(Nav);
  return (
    <div className="looking">
      {list.dataM &&
        list.dataM.length > 0 &&
        list.dataM.map((em, index) => {
          if (type == em.Description) {
            return <Card cardOptions={em}></Card>;
          }
        })}
    </div>
  );
}
