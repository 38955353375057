import { Swiper, SwiperSlide } from "swiper/react";
import RightPage from "../../Components/RightPage";

import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Keyboard,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./style.css";
import test from "./test.png";
import i1 from "./img/1.png";
import i2 from "./img/2.png";
import vk from "./img/vk.svg";
import tg from "./img/tg.svg";
import arrow from "./img/arrow.svg";
import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps";
export default function Contacts() {
  return (
    <div className="Page">
      <div className="contactBody">
        <h2>КОНТАКТЫ</h2>
        <div className="flexHeight">
          <div className="contactTel">
            <p>Телефон</p>
            <a href="tel:+79253338363">+7(925)333 83 63</a>
            <div></div>
          </div>
          <div className="contactTel">
            <p>Время работы</p>
            <a>пн-пт 10:00-19:00</a>
            <div></div>
            <a>сб-вс выходной </a>
          </div>
          <div className="contactTel nedx">
            <p>Социальные сети</p>
            <a
              className="socialLink"
              href="https://vk.com/club225084983"
              target="_blank"
            >
              <img src={vk} alt="" />
              <div>
                <p>
                  RolexTeam
                  <img src={arrow} alt="" />
                </p>
                <span>
                  Задайте нам вопрос в личные сообщения <br /> и следить за
                  акциями и новостями.
                </span>
              </div>
            </a>

            <a
              className="socialLink"
              href="https://t.me/internetvitrina"
              target="_blank"
            >
              <img src={tg} alt="" />
              <div>
                <p>
                  t.me/RolexTeam
                  <img src={arrow} alt="" />
                </p>
                <span>
                  Наши работы, акции, оперативные <br /> ответы на ваши
                  возникшие вопросы
                </span>
              </div>
            </a>
          </div>
          <Swiper
            slidesPerView={document.body.clientWidth > 640 ? 2 : 1}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            keyboard={{
              enabled: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation, Keyboard]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="cennEm">
                <img src={i1} alt="" />
                <p>Иванова Маргарита</p>
                <span>Менеджер по товару</span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cennEm">
                <img src={i2} alt="" />
                <p>Петров Александр</p>
                <span>Менеджер по товару</span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cennEm">
                <img src={i1} alt="" />
                <p>Иванова Маргарита</p>
                <span>Менеджер по товару</span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cennEm">
                <img src={i2} alt="" />
                <p>Петров Александр</p>
                <span>Менеджер по товару</span>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="map">
            <YMaps
              query={{
                apikey: "8b8beae0-96e7-40a2-ad2f-0b09f798e2e1",
              }}
            >
              <Map
                defaultState={{
                  center: [55.801293, 49.111872],
                  zoom: 16,
                }}
                width="100%"
                height="100%"
              >
                <Placemark geometry={[55.801293, 49.111872]} />
              </Map>
            </YMaps>
          </div>
        </div>
      </div>
      <RightPage></RightPage>
    </div>
  );
}
