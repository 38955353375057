import { useContext, useEffect, useState } from "react";
import ThemeCont from "../../Components/Theme";
import { Theme } from "../../Contexts/Theme";
import icon1 from "./img/icon1.svg";
import icon2 from "./img/icon2.svg";
import icon3 from "./img/icon3.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import "./style.css";
import loop from "../../Icons/Loop.svg";
import { Pagination } from "swiper/modules";
import icon4 from "./img/icon4.svg";
import "./style.css";
import { routes } from "../../routes";
import { Link, useNavigate } from "react-router-dom";
import RightPage from "../../Components/RightPage";
import CardList from "../../Containers/CardList";
import { Nav } from "../../Contexts/Nav";
import { id } from "../../Consts/Const";
import robot from "./img/robot.png";
import { DataMain } from "../../Contexts/Data";
import MobileLinks from "../../Components/MobileLinks";
import ServiceSearch from "../../Components/ServiceSearch";
import { Store } from "react-notifications-component";
import { User } from "../../Contexts/User";

export default function Main({ setSearch, search }) {
  const [theme, setTheme] = useContext(Theme);
  const [data, setData] = useState([]);
  const [video, setVideo] = useContext(DataMain);
  const [stocks, setStocks] = useContext(DataMain);
  const [voices, setVoice] = useState("");
  const Navigate = useNavigate();
  useEffect(() => {
    setSearch(voices);
  }, [voices]);
  function listen() {
    let recognition = new window.webkitSpeechRecognition();
    recognition.lang = "ru-RU";
    recognition.interimResults = true;
    recognition.start();

    recognition.onresult = function (event) {
      event.results[0][0] &&
        event.results[0][0].transcript &&
        setVoice(event.results[0][0].transcript);
    };
  }

  const [nav, setNav] = useContext(Nav);
  return (
    <div className="main">
      <div className="mainBody">
        {document.body.clientWidth < 640 && (
          <ServiceSearch search={search} setSearch={setSearch}></ServiceSearch>
        )}
        {document.body.clientWidth < 640 && (
          <div className="mobileSwipeMain">
            <Swiper
              grabCursor={true}
              effect={"creative"}
              creativeEffect={{
                prev: {
                  shadow: true,
                  translate: [0, 0, -400],
                },
                next: {
                  translate: ["100%", 0, 0],
                },
              }}
              pagination={true}
              modules={[EffectCreative, Pagination]}
              className="mySwiper"
            >
              {stocks.stocks.length > 0 &&
                stocks.stocks.map((em, index) => {
                  return (
                    <div className="stocks">
                      <SwiperSlide onClick={() => Navigate(`/card/54`)}>
                        <video
                          src={
                            video && video.userInfo
                              ? JSON.parse(video.userInfo.Actions)[0]
                              : ""
                          }
                          muted={true}
                          autoPlay={true}
                          playsInline
                          controls={false}
                          loop={true}
                        ></video>
                      </SwiperSlide>
                      <SwiperSlide>
                        <video
                          src="/videoTechnic/2.webm"
                          muted={true}
                          playsInline
                          controls={false}
                          autoPlay={true}
                          loop={true}
                          onClick={() => Navigate(`/card/51`)}
                        ></video>
                      </SwiperSlide>
                    </div>
                  );
                })}
            </Swiper>
            {document.body.clientWidth < 640 && <MobileLinks></MobileLinks>}
            <div className="catMob" onClick={() => Navigate(routes.services)}>
              <p>Каталог товаров</p>
              <span>Смотреть все ({stocks.dataM && stocks.dataM.length})</span>
            </div>
          </div>
        )}
        <div className="headerMain searchInput">
          {/*  <ThemeCont></ThemeCont> */}
          <ServiceSearch setSearch={setSearch} search={search}></ServiceSearch>
          <Link
            onClick={() => {
              Store.addNotification({
                title: "Ai ассистент:",
                message: `Продиктуйте мне фразу для поиска товаров`,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__bounceIn"],
                animationOut: ["animate__animated", "animate__backOutUp"],
                dismiss: {
                  duration: 5000,
                  onScreen: true,
                },
              });
              listen();
            }}
            className="robot"
          >
            <img src={robot} alt="" /> Ai ассистент
          </Link>
          {document.body.clientWidth > 1500 && (
            <Link to={routes.services}>Перейти в Каталог</Link>
          )}
        </div>

        <CardList
          search={search}
          list={document.body.clientWidth > 640 ? data : data.slice(0, 8)}
          width={17}
        ></CardList>
      </div>
      <RightPage></RightPage>
    </div>
  );
}
