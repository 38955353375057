import { useContext, useEffect, useState } from "react";
import "./style.css";
import { id } from "../../../Consts/Const";
import { Store } from "react-notifications-component";
import { User } from "../../../Contexts/User";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import { BusketV } from "../../../Contexts/Busket";
export default function BusketSumm({ list }) {
  const [summ, setSumm] = useState(0);
  const [user, setUser] = useContext(User);
  const [busket, setBusket] = useContext(BusketV);
  const Navigate = useNavigate();
  useEffect(() => {
    let newSumm = 0;
    list.length > 0 &&
      list.forEach((element) => {
        if (element.Property) {
          newSumm +=
            element.Price * (element.Quantity != null ? element.Quantity : 1);
        }
      });
    console.log(list);
    setSumm(newSumm);
  }, [list]);
  function sendOrder() {
    let form = new FormData();
    if (localStorage.user) {
      form.append("idUser", JSON.parse(localStorage.user).ID);
    } else {
      form.append("idUser", "524");
    }

    form.append(
      "positions",
      JSON.stringify(
        list.map((em) => {
          return {
            length: em.Quantity != null ? em.Quantity : "1",
            id: em.ID,
          };
        })
      )
    );
    form.append("idComp", id);
    form.append("status", "Создан");
    form.append("date", JSON.stringify(new Date()));
    form.append("tel", user ? user.Phone : "+7(910)818-47-01");
    form.append("sum", summ);
    fetch("https://comeback-team.ru/sellOrder.php", {
      method: "POST",
      body: form,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        Store.addNotification({
          title: "Заказ создан",
          message: `В течение 10 минут с вами свяжется менеджер для уточнения деталей заказа`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__bounceIn"],
          animationOut: ["animate__animated", "animate__backOutUp"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        setBusket([]);
        if (user.length > 0) {
          Navigate(routes.profile);
        } else {
          Navigate(routes.main);
        }
      });
  }
  return (
    <div className="BusketSumm">
      <h4>К оплате</h4>
      {list.length > 0 &&
        list.map((em, index) => {
          return (
            <div className="busketSummEm" key={"summ" + index}>
              <p>{em.Title}</p>
              <span>{em.Quantity != null ? em.Quantity : "1"}</span>
              <div>
                {em.Price * (em.Quantity != null ? em.Quantity : 1) + " руб."}
              </div>
            </div>
          );
        })}

      <div className="busketFinalSumm">
        <p>Сумма заказа:</p>
        <span>{summ} руб.</span>
      </div>
      <div
        className="button"
        style={
          list.length == 0 ? { pointerEvents: "none", opacity: "0.6" } : {}
        }
        onClick={() => sendOrder()}
      >
        Оформить заказ
      </div>
    </div>
  );
}
