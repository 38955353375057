import { Store } from "react-notifications-component";
import React from "react";
export default function Notice(title, text, note) {
  Store.addNotification({
    title: title,
    message: text,
    type: note,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__bounceIn"],
    animationOut: ["animate__animated", "animate__backOutUp"],
    dismiss: {
      duration: 2500,
      onScreen: true,
    },
  });
}
