import RightPage from "../../Components/RightPage";
import test from "./test.png";
import "./style.css";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { BusketV } from "../../Contexts/Busket";
import BusketEm from "../../Components/BusketComponents/BusketItem/BusketEm";
import BusketSumm from "../../Components/BusketComponents/BusketSumm/BusketSumm";
import { Fade } from "react-reveal";

export default function Busket() {
  const [busket, setBusket] = useContext(BusketV);
  const [busketEnd, setBusketEnd] = useState([]);
  const [selectAll, isSelectAll] = useState(true);

  function cleanBusket() {
    setBusket(busket.filter((em) => em.Property != true));
    localStorage.busket = JSON.stringify([]);
  }
  useEffect(() => {
    setBusket(busket.map((elem) => true && { ...elem, Property: selectAll }));
  }, [selectAll]);
  return (
    <div className="Page">
      <div className="busketBody">
        <div className="busketTop">
          <p>Корзина</p>
          <div
            onClick={() => isSelectAll(!selectAll)}
            className={selectAll ? "checkAct" : "checkFor"}
          ></div>
          <span>Выбрать все</span>
          <div className="deleteItem" onClick={() => cleanBusket()}>
            Удалить выбранные
          </div>
        </div>

        <div className="busketElems">
          {busket &&
            busket.length > 0 &&
            busket.map((em, index) => {
              const img = em.Photo.split(" ");
              return <BusketEm em={em} index={index} img={img}></BusketEm>;
            })}
        </div>

        {busket && busket.length == 0 && (
          <p className="corzClear">Ваша корзина пуста!</p>
        )}
      </div>
      {busket.length > 0 ? (
        <BusketSumm
          list={busket.filter((em) => em.Property == true)}
        ></BusketSumm>
      ) : (
        <RightPage></RightPage>
      )}
    </div>
  );
}
